import { useState, useEffect } from "react";
import Map from "./map";

const libraries = ["places"];

export default function GoogleMap() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const scriptId = "google-maps-script";
    if (document.getElementById(scriptId)) {
      setIsLoaded(true); // Script already exists
      return;
    }

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }&libraries=${libraries.join(",")}`;
    script.async = true; // Force async
    script.defer = true; // Force defer
    script.onload = () => setIsLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.getElementById(scriptId)?.remove(); // Cleanup script on unmount
    };
  }, []);

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}
