import React from "react";
import Map from "../GoogleMap";
import { TbChristmasTree } from "react-icons/tb";
import { xmasclose, nyopen } from "../Xmas";
import styled from "styled-components";

export const Container = styled.div`
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-lightgrey);

  @media screen and (max-width: 768px) {
    padding: auto 0;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  z-index: 2;
  height: auto;
  width: 100vw;
  max-width: 1100px;
  padding: 150px 25px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;
`;

export const Row = styled.div`
  display: grid;
  grid-auto-columns: repeat(2, 1fr);
  align-items: top;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1 col1" "col2 col2";
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 25px;
  grid-area: col1;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 25px;
  grid-area: col2;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const Column3 = styled.div`
  margin-bottom: 5px;
  padding: 0 15px;
  grid-area: col2;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 0px;
`;

export const TopLine = styled.div`
  color: ${({ lightText }) =>
    lightText ? "var(--color-darkpink)" : "var(--color-darkpink)"};
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: ${({ imgStart }) => (imgStart ? "right" : "left")};
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 52px;
  line-height: 58px;
  font-weight: 600;
  color: ${({ lightText }) =>
    lightText ? "var(--color-darkergrey)" : "var(--color-lightergrey)"};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.div`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 34px;
  color: ${({ lightText }) =>
    lightText ? "var(--color-darkergrey)" : "var(--color-lightergrey)"};

  ul {
    text-decoration: none;
    list-style: none;
    text-align: left;

    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
`;

export const BtnWrap = styled.div`
  display: ${({ buttonOn }) => (buttonOn ? "flex" : "none")};
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 40px 0;
  padding-right: 0;
  border-radius: 5%;
`;

const EnquireSection = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <Row>
            <Column1>
              <TextWrapper>
                <TopLine>Enquire</TopLine>
                <Heading>Get in Touch</Heading>
                <Subtitle>
                  At Wheel of Health we care about your future, if we can help
                  you further then please contact us. Our head office is based
                  in Southampton, Hampshire and we are always on the end of the
                  phone or email to help you.
                </Subtitle>
                <Subtitle>
                  Wheelchair and 24-Hour Posture Management is our specialty.
                  Providing solutions and resources you need in support of your
                  independence. From initial contact, to liaison, to final fit,
                  with ongoing support and ongoing reviews to suit you, we’ve
                  got you covered.
                </Subtitle>
                <Subtitle></Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <TextWrapper>
                <TopLine>Head</TopLine>
                <Heading>Office</Heading>
                <Subtitle>
                  <ul>
                    <li>17 Monks Wood Close</li>
                    <li>Bassett</li>
                    <li>Southampton</li>
                    <li>Hampshire</li>
                    <li>SO16 3TT</li>
                    <li>United Kingdom</li>
                    <li>+44 (0) 23 8076 8583</li>
                  </ul>
                </Subtitle>
              </TextWrapper>
            </Column2>
          </Row>
          <Row>
            <Column1 style={{}}>
              <TextWrapper>
                {new Date() >= new Date(xmasclose) &&
                new Date() <= new Date(nyopen) ? (
                  <TopLine>Festive Period</TopLine>
                ) : (
                  <TopLine>Business</TopLine>
                )}
                {new Date() >= new Date(xmasclose) &&
                new Date() <= new Date(nyopen) ? (
                  <Heading>Closed</Heading>
                ) : (
                  <Heading>Hours</Heading>
                )}
                {new Date() >= new Date(xmasclose) &&
                new Date() <= new Date(nyopen) ? (
                  <Subtitle>
                    <TbChristmasTree
                      style={{
                        fontSize: "100px",
                      }}
                    />
                  </Subtitle>
                ) : null}
              </TextWrapper>
              <TextWrapper>
                {new Date() >= new Date(xmasclose) &&
                new Date() <= new Date(nyopen) ? (
                  <Subtitle>
                    <ul>
                      <li>
                        Please note, the office will be closed this year from
                      </li>
                      <li>
                        Thursday 19th December 14:00hrs and will be reopening
                        Tuesday 7th January 09:00hrs.
                      </li>
                      <br />
                      <li>
                        For urgent enquiries over the festive period, please
                        contact the office and your call will be returned as
                        soon as possible.
                      </li>
                    </ul>
                  </Subtitle>
                ) : (
                  <Subtitle>
                    <ul>
                      <li>Monday: 09:00 - 17:00hrs</li>
                      <li>Tuesday: 09:00 - 17:00hrs</li>
                      <li>Wednesday: 09:00 - 17:00hrs</li>
                      <li>Thursday: 09:00 - 17:00hrs</li>
                      <li>Friday: 09:00 - 17:00hrs</li>
                      <li>Saturday: CLOSED</li>
                      <li>Sunday: CLOSED</li>
                    </ul>
                  </Subtitle>
                )}
              </TextWrapper>
            </Column1>
          </Row>
        </Wrapper>
      </Container>
      <Container style={{ background: "var(--color-black)" }}>
        <Wrapper style={{ padding: "50px 50px" }}>
          <Row>
            <Column1>
              <TextWrapper>
                <TopLine>Enquire</TopLine>
                <Heading>Our Team</Heading>
              </TextWrapper>
            </Column1>
            <Column3>
              <TextWrapper></TextWrapper>
            </Column3>
          </Row>
        </Wrapper>
      </Container>
      <Map />
    </>
  );
};

export default EnquireSection;
