// Navbar.js

import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import styled from "styled-components";
import images from "../../images";
import { xmas, box, xmasclose, nyopen, ChristmasTree } from "../Xmas";

// Styled Components

const Nav = styled.nav`
  background: ${({ scrollNav }) =>
    scrollNav ? "rgba(0,0,0,0.95)" : "transparent"};
  transition: 0.6s all ease-in-out;
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 998;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 14px;
  font-family: var(--font-base);
  font-weight: bold;
  text-decoration: none;

  & img {
    width: 150px;

    @media screen and (max-width: 768px) {
      width: 110px;
    }
  }

  &:hover {
    filter: brightness(110%);
  }
`;

const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;

    &:hover {
      color: ${({ xmas }) =>
        xmas ? "var(--color-xmas)" : "var(--color-darkpink)"};
      filter: brightness(110%);
    }
  }
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  margin: auto 20px;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  height: 80px;
`;

const NavLinks = styled(LinkR)`
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 0.8rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: ${({ xmas }) =>
      xmas ? "var(--color-xmas)" : "var(--color-darkpink)"};
    padding-top: 2px;
    border-bottom: ${({ xmas }) =>
      xmas ? "2px solid var(--color-xmas)" : "2px solid var(--color-darkpink)"};
  }

  &.active {
    border-bottom: ${({ xmas }) =>
      xmas ? "var(--color-xmas)" : "var(--color-darkpink)"};
  }
`;

const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavBtnLink = styled.div`
  border-radius: 10px;
  white-space: nowrap;
  padding: 5px 5px;
  color: #fff;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  & img {
    width: 30px;
    height: auto;
  }

  a {
    &:hover {
      filter: brightness(120%);
      padding-bottom: 30px;
      border-bottom: ${({ xmas }) =>
        xmas
          ? "2px solid var(--color-xmas)"
          : "2px solid var(--color-darkpink)"};
    }
  }
`;

// Navbar Component

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isXmas, setIsXmas] = useState(false);
  const [isXmasClosed, setIsXmasClosed] = useState(false);

  const changeNav = () => {
    setScrollNav(window.scrollY >= 40);
  };

  const toggleHome = () => scroll.scrollToTop();

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    const now = new Date();
    setIsXmas(now >= new Date(xmas) && now <= new Date(box));
    setIsXmasClosed(now >= new Date(xmasclose) && now <= new Date(nyopen));
  }, []);

  return (
    <Nav
      scrollNav={scrollNav}
      className={
        isXmas && scrollNav ? "lightStickyXmas" : scrollNav ? "lightSticky" : ""
      }>
      <NavbarContainer>
        <NavLogo to="/" onClick={toggleHome}>
          <img src={images.wofhLogo} alt="Wheel of Health Logo" />
        </NavLogo>
        <MobileIcon onClick={toggle} xmas={isXmas ? "true" : undefined}>
          <FaBars />
        </MobileIcon>

        <NavMenu>
          <NavItem>
            <NavLinks
              to="/discover"
              onClick={toggleHome}
              xmas={isXmas ? "true" : undefined}>
              Discover
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to="/partnership"
              onClick={toggleHome}
              xmas={isXmas ? "true" : undefined}>
              Partnership
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks
              to={isXmasClosed ? "/festive-opening" : "/enquire"}
              onClick={toggleHome}
              xmas={isXmas || isXmasClosed ? "true" : undefined}
              aria-label={isXmasClosed ? "Festive Opening" : "Enquire"}>
              {isXmasClosed && <ChristmasTree style={{ marginRight: "8px" }} />}
              {isXmasClosed ? "Festive Opening" : "Enquire"}
            </NavLinks>
          </NavItem>
        </NavMenu>

        <NavBtn>
          <NavBtnLink xmas={isXmas ? "true" : undefined}>
            <a
              href="https://qunote3.net"
              target="_blank"
              rel="noopener noreferrer">
              <img src={images.qunoteLogin} alt="CaseNote Login" />
            </a>
          </NavBtnLink>
          <NavBtnLink xmas={isXmas ? "true" : undefined}>
            <a
              href="https://www.wheelofhealth.co.uk/login"
              target="_blank"
              rel="noopener noreferrer">
              <img src={images.wofhLogoWheel} alt="Associate Login" />
            </a>
          </NavBtnLink>
        </NavBtn>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
