import React from "react";
import Snowfall from "react-snowfall";

export default function Snow() {
  return (
    <div
      style={{
        position: "fixed", // Fixed positioning to cover the entire page
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 999, // Place snow above everything
        pointerEvents: "none", // Ensure snow does not block interactions
      }}>
      <Snowfall
        snowflakeCount={400}
        color="#f6f8ff"
        radius={[0.2, 2.8]}
        speed={[1.0, 4.0]}
        wind={[-0.5, 2.0]}
        changeFrequency={400}
        rotationSpeed={[-1, 4.0]}
      />
    </div>
  );
}
